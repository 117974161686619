import React from 'react'
import UnderConstructionPage from './UnderonstructionPage'

function Ourteam() {
  return (
    <div>
      <UnderConstructionPage />
    </div>
  )
}

export default Ourteam